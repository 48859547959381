import { cx } from '@linaria/core';
import useEmblaCarousel from 'embla-carousel-react';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Heading, Text } from '@indriver/nova';
import { mediaQueries, useBreakpoints } from 'common/lib';
import * as Styled from './instruction-section.styles';
import { getInstructions, IInstructionType } from './lib/get-instructions';

interface IDotProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    selected: boolean;
    heading?: string;
    text?: string;
}

const DotButton = ({ selected, ...props }: IDotProps) => (
    <button className={cx('embla__dot', selected && 'is-selected')} type='button' {...props} />
);

const DesktopDotButton = ({ selected, heading, text, ...props }: IDotProps) => (
    <Styled.DesktopDotButton type='button' selected={selected} {...props}>
        <Heading type='h2' margin='xs'>
            {heading}
        </Heading>
        <Text size='ml'>{text}</Text>
    </Styled.DesktopDotButton>
);

interface ILayoutProps {
    instructions: IInstructionType[];
    scrollSnaps: number[];
    scrollTo: (index: number) => void;
    selectedIndex: number;
    viewportRef: React.RefCallback<HTMLElement>;
}

const Mobile = ({ instructions, scrollSnaps, scrollTo, selectedIndex, viewportRef }: ILayoutProps) => {
    const { t } = useTranslation();
    return (
        <Styled.InstructionSection>
            <Styled.Heading>{t('main_page_instruction_section_heading')}</Styled.Heading>
            <div className='embla'>
                <div className='embla__viewport' ref={viewportRef}>
                    <div className='embla__container'>
                        {instructions.map((slide) => (
                            <div key={slide.id} className='embla__slide'>
                                <Styled.DesktopSlide>
                                    <div>
                                        <Styled.SubHeading>{slide.heading}</Styled.SubHeading>
                                        <Styled.SubText>{slide.text}</Styled.SubText>
                                    </div>
                                    <Styled.ImageWrapper>
                                        <Styled.Image
                                            src={slide.image.url}
                                            width={slide.image.width}
                                            height={slide.image.height}
                                            alt=''
                                        />
                                    </Styled.ImageWrapper>
                                </Styled.DesktopSlide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='embla__dots'>
                {scrollSnaps.map((_, index) => (
                    <DotButton
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        selected={index === selectedIndex}
                        aria-label={`Перейти на слайд ${index + 1}`}
                        onClick={() => scrollTo(index)}
                    />
                ))}
            </div>
        </Styled.InstructionSection>
    );
};

const Desktop = ({ instructions, scrollSnaps, scrollTo, selectedIndex, viewportRef }: ILayoutProps) => {
    const { t, i18n } = useTranslation();
    const dir = i18n.dir();

    return (
        <Styled.InstructionSection>
            <div className='embla'>
                <div className='embla__viewport' ref={viewportRef}>
                    <div className='embla__container'>
                        {instructions.map((slide) => (
                            <div key={slide.id} className='embla__slide'>
                                <Styled.ImageWrapper>
                                    <Styled.Image
                                        src={slide.image.url}
                                        width={slide.image.width}
                                        height={slide.image.height}
                                        alt=''
                                        loading='eager'
                                    />
                                </Styled.ImageWrapper>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Styled.DesktopCarouselControls>
                <Styled.Heading>{t('main_page_instruction_section_heading')}</Styled.Heading>
                <Styled.DotsWrapper>
                    {scrollSnaps.map((_, index) => (
                        <DesktopDotButton
                            key={instructions[index].id}
                            dir={dir}
                            heading={instructions[index].heading}
                            text={instructions[index].text}
                            selected={index === selectedIndex}
                            onClick={() => scrollTo(index)}
                        />
                    ))}
                </Styled.DotsWrapper>
            </Styled.DesktopCarouselControls>
        </Styled.InstructionSection>
    );
};

export const InstructionSection = () => {
    const media = useBreakpoints();

    const { t, i18n } = useTranslation();
    const [viewportRef, embla] = useEmblaCarousel({
        loop: true,
        direction: i18n.dir(),
        breakpoints: {
            [mediaQueries.gtL]: {
                direction: 'ltr',
                axis: 'y',
            },
        },
    });
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);

    const scrollTo = React.useCallback((index) => embla?.scrollTo(index), [embla]);

    const onSelect = React.useCallback(() => {
        if (!embla) {
            return;
        }
        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    React.useEffect(() => {
        if (!embla) {
            return;
        }
        onSelect();
        setScrollSnaps(embla.scrollSnapList());
        embla.on('select', onSelect);
    }, [embla, setScrollSnaps, onSelect]);

    const instructions = React.useMemo(() => getInstructions(t, i18n.resolvedLanguage), [i18n.resolvedLanguage, t]);

    return media.isGtL ? (
        <Desktop
            instructions={instructions}
            scrollSnaps={scrollSnaps}
            scrollTo={scrollTo}
            selectedIndex={selectedIndex}
            viewportRef={viewportRef}
        />
    ) : (
        <Mobile
            instructions={instructions}
            scrollSnaps={scrollSnaps}
            scrollTo={scrollTo}
            selectedIndex={selectedIndex}
            viewportRef={viewportRef}
        />
    );
};
