import type { TFunction } from 'next-i18next';

export interface IInstructionType {
    id: string;
    heading: string;
    text: string;
    image: {
        url: string;
        width: number;
        height: number;
    };
}

export const getInstructions = (t: TFunction, locale: string): IInstructionType[] => [
    {
        id: '1',
        heading: t('main_page_instruction_section_first_slide_heading'),
        text: t('main_page_instruction_section_first_slide_description'),
        image: {
            url: `/images/main-page/instructions/${locale}-slide-1.png`,
            width: 456,
            height: 480,
        },
    },
    {
        id: '2',
        heading: t('main_page_instruction_section_second_slide_heading'),
        text: t('main_page_instruction_section_second_slide_description'),
        image: {
            url: `/images/main-page/instructions/${locale}-slide-2.png`,
            width: 498,
            height: 433,
        },
    },
    {
        id: '3',
        heading: t('main_page_instruction_section_third_slide_heading'),
        text: t('main_page_instruction_section_third_slide_description'),
        image: {
            url: `/images/main-page/instructions/${locale}-slide-3.png`,
            width: 520,
            height: 229,
        },
    },
];
